import Service from '../Service'
import { LandingV4Instance } from './Instance'

export class StartService extends Service {
  constructor(tenant, broker) {
    super()
    this._hostName = window ? window.location.host : 'localhost'
    this.setTenant(tenant)
    this.setBroker(broker)
    this.setOperatorEmail('')
    this.setLandingVersion('1')
    this._utm = {
      source: '',
      medium: '',
      campaign: '',
      term: '',
      content: '',
      browserId: '',
      clickId: '',
    }
  }
  setTenant(tenant) {
    this._tenant = `${tenant}`
  }
  setBroker(broker) {
    this._broker = broker
  }
  setUtmSource(source) {
    this._utm = { ...this._utm, source: source }
  }
  setUtmMedium(medium) {
    this._utm = { ...this._utm, medium: medium }
  }
  setUtmCampaign(campaign) {
    this._utm = { ...this._utm, campaign: campaign }
  }
  setUtmTerm(term) {
    this._utm = { ...this._utm, term: term }
  }
  setUtmContent(content) {
    this._utm = { ...this._utm, content: content }
  }
  setOperatorEmail(operatorEmail) {
    this._operatorEmail = operatorEmail
  }
  setLandingVersion(landingVersion) {
    this._landingVersion = landingVersion
  }

  setBrowserId(fbp) {
    this._utm = { ...this._utm, browserId: fbp }
  }
  setClickId(fbc) {
    this._utm = { ...this._utm, clickId: fbc }
  }

  get data() {
    return {
      hostName: `${this._hostName}`,
      operatorEmail: this._operatorEmail,
      landingVersion: this._landingVersion,
      tenant: `${this._tenant}`,
      broker: this._broker,
      utm: { ...this._utm },
    }
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'POST',
      url: '/begin/landing',
      data: self.data,
    }))
  }
}