import axios from 'axios'

let serviceUrl = ''

switch (process.env.GATSBY_ACTIVE_ENV) {
  case 'production':
    serviceUrl = '/v2'
    break
  case 'qa':
  case 'staging':
  case 'test':
    serviceUrl = '/v2'
    break
  default:
    serviceUrl = '/v2'
}

export const LandingLowCostInstance = axios.create({
  baseURL: serviceUrl,
  timeout: 90000
})
