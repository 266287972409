import Service from '../Service'
import { LandingV4Instance } from './Instance'
import { LandingLowCostInstance } from './InstanceLowCost'
import { LandingTysonInstance } from './InstanceTyson'

/**
 * @class
 */
export class CataloguesService extends Service {

  /**
 * @param  {String} NumberTel
 * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
 */
  postPhoneValidate(creditApplicationId, phoneNumber) {
    return this._requestWrapper(LandingV4Instance({
      method: 'POST',
      url: '/validations/phone',
      data: {
        creditApplicationId: `${creditApplicationId}`,
        phoneNumber: `${phoneNumber}`,
      }
    }))
  }

  /**
   * VALIDA TELEFONOS QUE NO SEAN REPETYDOS POR NUMERO Y TYPO
 * @param  {String} NumberTel
 * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
 */
  postPhoneValidateType(creditApplicationId, phoneNumber, phoneType) {
    return this._requestWrapper(LandingV4Instance({
      method: 'POST',
      url: '/validations/phone-type',
      data: {
        creditApplicationId: `${creditApplicationId}`,
        phoneNumber: `${phoneNumber}`,
        phoneType: `${phoneType}`
      }
    }))
  }

  /**
* @param  {String} Email
* @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
*/
  postEmailValidate(creditApplicationId, email) {
    return this._requestWrapper(LandingV4Instance({
      method: 'POST',
      url: '/validations/email',
      data: {
        creditApplicationId: `${creditApplicationId}`,
        email: `${email}`,
      }
    }))
  }


  /**
   * @param  {String} PostalCode
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  identityBiometrics(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: '/verifications/identity-biometrics',
      params: {
        creditApplicationId
      }
    }))
  }

  /**
   * @param  {String} creditApplicationId
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  identityVirtual(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: '/verifications/identity-virtual',
      params: {
        creditApplicationId
      }
    }))
  }

  /**
   * @param  {String} PostalCode
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  getNeighborhoods(PostalCode, creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: '/customer/neighborhoods',
      params: {
        PostalCode,
        creditApplicationId
      }
    }))
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  customer(tenant, creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: '/customer',
      params: {
        creditApplicationId,
        tenant
      }
    }))
  }

    /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
    customerTyson(tenant, creditApplicationId) {
      return this._requestWrapper(LandingTysonInstance({
        method: 'GET',
        url: '/customer',
        params: {
          creditApplicationId,
          tenant
        }
      }))
    }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  getStates(tenant) {
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: '/customer/states',
      params: {
        tenant
      }
    }))
  }

  /**
   * @param {String} landingApplicationId
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  getScoreCatalogues() {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: '/score/catalogs',
      // params: {
      //   landingApplicationId
      // }
    }))
  }

  /**
 * @param {String} creditApplicationId
 * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
 */
  getSurveys(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: '/surveys/nps',
      params: {
        creditApplicationId
      }
    }))
  }

  /**
   * @param {String} landingApplicationId
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  getCataloguesRelationship(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: '/insurance/relationships',
      params: {
        creditApplicationId,
      }
    }))
  }
  /**
 * @param {String} creditApplicationId
 * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
 */
  getSignature(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/signature/${creditApplicationId}`,
    }))
  }

  /**
* @param {String} creditApplicationId
* @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
*/
  getPrescore(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/prescore?creditApplicationId=${creditApplicationId}&baseUtcOffset=-5`,
    }))
  }

  /**
* @param {String} creditApplicationId
* @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
*/
  getPrescoreNip(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/prescore/nip?creditApplicationId=${creditApplicationId}&baseUtcOffset=-5`,
    }))
  }

  /**
 * @param {String} creditApplicationId
 * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
 */
  getPreapproved(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/preapproved`,
      params: {
        creditApplicationId,
      }
    }))
  }
  /**
   * @param {String} creditApplicationId
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  getCongratulations(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/congratulations`,
      params: {
        creditApplicationId,
      }
    }))
  }

  /**
  * @param {String} creditApplicationId
  * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
  */
  getLegales(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/legal`,
      params: {
        creditApplicationId,
      }
    }))
  }

  /**
  * @param {String} creditApplicationId
  * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
  */
  getBrokers(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/landing`,
      params: {
        creditApplicationId,
      }
    }))
  }

  /**
  * @param {String} creditApplicationId
  * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
  */
  getIncomeIngresos(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/income`,
      params: {
        creditApplicationId,
      }
    }))
  }
  /**
  * @param {String} paymentFrequencyId
  * @param {String} creditApplicationId
  * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
  */
  getIncomeSpec(paymentFrequencyId, creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/income/spec`,
      params: {
        paymentFrequencyId,
        creditApplicationId
      }
    }))
  }
  /**
  * @param {String} paymentFrequencyId
  * @param {String} creditApplicationId
  * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
  */
  getCustomerApp(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/customersapp`,
      responseType: 'blob',
      params: {
        creditApplicationId
      }
    }))
  }

  /**
  * @param {String} creditApplicationId
  * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
  */
  getReferencesCommercial(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/references/commercial`,
      params: {
        creditApplicationId,
      }
    }))
  }

  /**
   * @param {String} creditApplicationId
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  getReferencesPersonal(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/references/personal`,
      params: {
        creditApplicationId,
      }
    }))
  }


  /**
  * @param {String} creditApplicationId
  * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
  */
  getOffer(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/offer`,
      params: {
        creditApplicationId,
      }
    }))
  }

  /**
  * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
  */
  getRenovaCurp(Curp) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/tube/renova`,
      params: {
        Curp,
      }
    }))
  }

  /**
  * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
  */
  getRenovaRfc(Rfc) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/tube/renova?Rfc=${Rfc}`,
      // params: {
      //   Rfc,
      // }
    }))
  }

  /**
  * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
  */
  getAmortizacion(data) {
    return this._requestWrapper(LandingLowCostInstance({
      method: 'post',
      url: `/begin/amortization-table`,
      data
    }))
  }

  /**
 * @param {String} creditApplicationId
 * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
 */
  getCreditDestinations() {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/tube/destinycredit`,
    }))
  }

  /**
* @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
*/
  getRenovaNombre(nombre, apaterno, amaterno, fechanac) {
    return this._requestWrapper(LandingV4Instance({
      method: 'get',
      url: `/tube/renova?Nombre=${nombre}&Apaterno=${apaterno}&Amaterno=${amaterno}&Fechanac=${fechanac}`,
    }))
  }
}